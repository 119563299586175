import { Routes as AppRoutes, Route } from "react-router-dom";

// import AboutPage from "../pages/AboutPage/AboutPage";
import PointsPage from "../pages/PointsPage/PointsPage";
import SwapPage from "../pages/SwapPage/SwapPage";
import KYC from "../pages/KYC/KYC";
import Earnings from "../pages/EarningsPage/Earnings";
import BridgePage from "../pages/BridgePage/BridgePage";

const Routes = () => {
  return (
    <AppRoutes>
      <Route path="/" element={<SwapPage />} />
      <Route path="/Deposit" element={<SwapPage />} />
      <Route path="/Earnings" element={<Earnings />} />
      <Route path="/Points" element={<PointsPage />} />
      {/* <Route path="/About" element={<AboutPage />} /> */}
      <Route path="/Bridge" element={<BridgePage />} />
      <Route path="/KYC" element={<KYC />} />
    </AppRoutes>
  );
};

export default Routes;
